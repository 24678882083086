import UserContacts from './UserContacts';

function App() {
  return (
    <div className="App">
        <UserContacts />
    </div>
  );
}

export default App;
